import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

:root {
  --main_color: #0c4795;
  --black_color:#1c1e22;
  --second_color: #f32528;
  --gray_color: #36383d36;
  --white: #ffffff;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
}
.pac-container.pac-logo {
    z-index: 98999999999999999999;
}
  body,html {
    margin: 0;
    padding: 0;

    height: -webkit-fill-available;
    font-family: 'Nunito', sans-serif;
  }
 a,h1,span,h2,h3,h4 {
  font-family: 'Nunito', sans-serif;
 }
 a{
  text-decoration: none;
  cursor: pointer;
  font-family: 'Nunito', sans-serif;
 }


.react-datepicker__day--keyboard-selected,.react-datepicker__day--selected:hover, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
    background-color: #278054;
}
.react-datepicker-popper{
    z-index: 999;
}
.sing_option {
    cursor: pointer;
}

.offcanvas-end {
    width: 50% !important;
    max-width: 700px;
    z-index: 99999;
    backdrop-filter: blur(20px);
    border-style: solid;
    border-color: #E7EBF0;
    border-width: 0;
    background: rgb(255 255 255);
}


.MuiIconButton-sizeMedium.MuiPhoneNumber-flagButton,.MuiIconButton-sizeMedium.MuiPhoneNumber-flagButton svg  {
  width: -moz-available;
  max-width: 50px;
}


.offcanvas-header {
    flex-direction: row-reverse;
}
.nav.nav-pills {
    margin: auto;
    width: fit-content;
}
.form_login{
  max-width: 500px;
    margin: auto;
    padding-top: 10px;
    
}

.form_login .form-control{
    height: 50px;
    border: none;
    box-shadow: aqua;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    font-size: 14px;
}
.form_login .input-group-text{
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  border: none;
  background-color: #ffffff;
}
.form_login .form-label {
    font-size: 16px;
    font-weight: 700;
    color: #8d8d8d;
    width: 100%;
}
.form_login button.btn.btn-primary {
  width: 100%;
    height: 50px;
    margin-top: 15px;
    background-color: #278054;
    border: none;
}
ul.mb-3.nav.nav-tabs li {
    width: 50%;
    border: none ;
    text-align: center;
    margin: auto;
}
.nav-link.active {
    font-size: 20px !important;
    color: #278054 !important;
    border-bottom: solid !important;
}
.nav-tabs .nav-link {
    color: #0000005e;
    font-size: 18px;
}
ul.mb-3.nav.nav-tabs {
    max-width:      500px;
    margin: 5% auto !important;
}
ul.mb-3.nav.nav-tabs button {
    width: 100%;
    margin: auto;
    border: none;
    text-transform: uppercase !important;
}

.form-control:focus {
    box-shadow: 0 0 0 0.25rem rgb(0 162 220 / 13%);
}
ul.mb-3.info.nav.nav-tabs {
    margin: 5% 0 !important;
    width: 100%;
    max-width: 100%;
}
.container {
    max-width: 1620px  !important;
}
div#eddfdf {
    width: 360px !important;
}
@media only screen and (max-width: 750px) {
    
    div#eddfdf {
    width: 100% !important;
}
  .offcanvas-end {
    width: 100% !important;
  }
}
/** */
.first {
    display: flex;
    justify-content: space-between;
    padding: 15px 15px;
    align-items: center;
    margin: auto;
    background-color:#278054;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.sacande {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.care {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
}
.id_more_info {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.state {
    padding: 5px 10px;
    background-color: #e79323;
    color: antiquewhite;
    border-radius: 18px;
    font-size: 12px;
    font-weight: 700;
    width: max-content;
}
.allHistorique p {
    margin: auto !important;
    margin-bottom: 0;
}
.sacande {
  padding: 20px 10px;
    background-color: #eceeec;
    color: #424242;

}
.care svg {
    width: 23px;
    height: 23px;
    margin: 5px;
}
p.time {
    font-size: 12px;
    color: #ffffff;
    margin: 0 !important;
}

.s1 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 15px;
}
.id_more_info p {
    margin: 0 10px !important;
    font-size: 14px;
}
.icon {
  background-color: #278054;
    padding: 0 10px;
    border-radius: 10px;
    color: #fff;
}
.icon svg {
    width: 20px;
    height: 20px;
}
.icon span {
    font-size: 13px;
    margin: 0 5px;
}
.s2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    padding: 20px 20px;

    background-color: #278054;
    color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.s3 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 20px 0;
}
.adr2 svg {
    color: #278054;
    width: 20px;
    height: 20px;
}
.adr1,.adr2 {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.s3 span {
    width: 200px;
    border-color: #278054 !important;
    border-style: solid;
    border-width: 1px;
    height: 1px;
    margin: auto 0;
}
.more_data.hid {
    display: none;
}
.sacande.hid {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.s4 {
  display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0px;
    background-color: #eceeec;
    border-radius: 11px;
    align-items: center;
}
.user_info {
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    margin: 12px;
}
.user_info svg{
  width: 25px;
    height: 25px;

}
.care {
    font-size: 14px;
    font-weight: 700;
}
.option {
    background-color: #eceeec61;
    border-style: solid;
    border-radius: 15px;
    border-width: 1px;
    border-color: #eceeec;
    margin: 15px 0;
}
.s3 p {
    text-align: center;
    font-size: 12px;
}
.s4 p.a {
    font-size: 12px;
    padding-right: 10px;
    text-align: center;
}
a.loder {
    /* left: 46%; */
    /* position: relative; */
    display: block;
    text-align: center;
}
a.loder {
    background-color: #278054;
    width: 167px;
    padding: 7px 0px;
    border-radius: 5px;
    color: #fff;
    margin: auto;
}
a.loder div {
    margin: auto;
}

.state.ver {
    background-color: #5b7e22;
    text-align: center;
}
.state.red {
    background-color: #e79323;
    text-align: center;
}
.hstack.gap-3 {
    padding: 10px 0;
    align-items: baseline !important;
}
.Toastify__toast-container {
    z-index: 999999;
}

div#eddfdf .offcanvas-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}
img.logoDrawer {
    max-width: 100px;
    margin-left: 30px;
}
.rccs {
    margin: auto;
    padding-bottom: 80px;
}
.ghModal {
    max-width: 700px;
}
/*main{
    max-width: 620px !important;
}*/
.valop input:focus-visible {
    border-color: #278054 !important;
    box-shadow: none !important;
    outline: none !important;
    border-style: solid;
    border-radius: 5px;
}
.css-1oqqzyl-MuiContainer-root {
    max-width: 1380px !important;
}

.menu_option .MuiPaper-root{
  width: 100% !important;
    max-width: 500px !important;
}

.MuiDialog-paperWidthSm {
    width: 100%;
}
.price_aff {
    margin-top: 40px;
    float: left;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 800;
}
.succ {
    color: #7c7c7c;
    font-size: 18px;
}
.final {
    font-size: 20px;
    color: #77b548;
}
div#VolOption .MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 900px;
}
.cardre {
    margin: 40px 0;
    padding: 10px;
}
.cardre .vol_des {
    margin-bottom: 50px;
}


.MuiButton-containedPrimary{
  background-color:#278054 !important;
}
.MuiInput-underline:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #278054 !important;
    pointer-events: none;
}
.MuiFormLabel-root.Mui-focused{
  color:#278054 !important;
}
.MuiButton-containedSecondary{
    background-color:#212121 !important;
}
.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    -webkit-letter-spacing: .01rem;
    -moz-letter-spacing: .01rem;
    -ms-letter-spacing: .01rem;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #ffffff00;
    border: 2px solid #278054;
    border-top: 0;
    border-radius: 0;
    line-height: 25px;
    height: 45px;
    width: 100%;
    outline: none;
    border-left: 0;
    border-right: 0;
}
.react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: #f5f5f500;
    border: 1px solid #cacaca00;
    border-radius: 3px 0 0 3px;
}
.form-control:focus{
    box-shadow:none !important;
}
`;
 
export default GlobalStyle;