
import * as React from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';

import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Card, InputAdornment } from '@mui/material';
import { useState } from 'react';
import { BsFillEyeFill,BsFillEyeSlashFill } from "react-icons/bs";
import { toast } from 'react-toastify';
import CmdSer from '../Service/CmdSer';

import { CartDispatchContext, CartStateContext, loginUser } from '../context/Main';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import Config from '../Config';

import OtpInput from 'react-otp-input';
import OtpTimer from 'otp-timer'
import firebase from '../firebase';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Styled = styled.div`
.sd {
    padding: 20px 20px;
    text-align: center;
    margin-top: 30px;
} 

.otpVal>div div input {
    height: 40px!important;
    width: 40px!important;
}
.otpVal>div {
    display: flex;
    align-items: center;
    margin: auto;
    width: fit-content;
}
.headerCF{
 margin: 5%;
    text-align: center;
    width: 100%;
 }
 .headerCF h3 {
    color: #278054 ;
    font-weight: 800;
    font-size: 25px;
}
.valop input:focus-visible {
    border-color: #278054  !important;
    box-shadow: none !important;
    outline: none !important;
    border-style: solid;
    border-radius: 5px;
}
    height: 100vh;
    padding-top: 70px;
    p#password-helper-text {
    text-align: end;
    margin: 0;
    color: red;
    }
    .df {
    margin-top: 20px;
}

`;

export default function Login() {
  const [viewpassword,setviewpassword] = useState(false);
  const navigate = useNavigate();
  const { islogin } = useContext(CartStateContext);
  const dispatch = useContext(CartDispatchContext);
  const[codeContry,SetcodeContry] = useState("fr");
  const [email,setemail] = useState("");
  const [pass,Setpass] = useState("");
  const [err,seterr] =  useState("");
  const [otp,setotp] = useState(null);
  const [loading,setloading] = useState(false);
  const [Disaible,setDisaible] = useState(true);
  const [restPass,setrestPass] = useState(false);
  const [ActiveRest,setActiveRest] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    setloading(true);
    if(email!="" && pass!=""){
      setTimeout(async () => {
          let data = null;
          if(Config.loginWithPhon){
             data =  await CmdSer.loginWithPhone({
              phone_number:email,pass:pass
            });
          }else{
             data =  await CmdSer.login({
              email:email,pass:pass
          });
          
          }
          if(data.data.id){
              const re = await CmdSer.userdata(data.data.id);
              loginUser(dispatch,re.data);
              setloading(false);
          }else{
            toast.error(`${Config.loginWithPhon?"Numéro de téléphone":"Email"} ou Mot de passe erroné`, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setloading(false);
            seterr(`${Config.loginWithPhon?"Numéro de téléphone":"Email"} ou Mot de passe erroné`);
            const ValidationMobil =  await CmdSer.verification(email);
            if(ValidationMobil.data.count!=0){
              setActiveRest(true);
              setloading(false);
            }
          }
         
        }, 1000)
  }else{
      seterr('');
      setloading(false);
  }

  };

  const getMycodeApi = async () => {
    const datare = await CmdSer.getMycode();
    SetcodeContry(datare.data.country_code.toLowerCase());
  }


  const validateAccount = async () => {
    setloading(true);
    setTimeout(async ()=>{
     window.confirmationResult.confirm(otp).then( async(result) => {
      const user = result.user;
      if(user.uid){
        const datas=  await CmdSer.getIdfromTlf(email);
        const re = await CmdSer.userdata(datas.data.id);
         loginUser(dispatch,re.data);
         window.location.reload(false);
      }
   }).catch((error) => {
     toast.error(error.message, {
       position: toast.POSITION.BOTTOM_LEFT,
     });
   });
     setloading(false);
    },1000);

  }



  const SandOtp = async () => {
    firebase.auth().signInWithPhoneNumber(email, new firebase.auth.RecaptchaVerifier('Recaptcha', {'size': 'invisible'}))
    .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        console.log("OTP has been sent");
        setDisaible(false);
    }).catch((error) => {
        seterr(error.message);
        console.log("SMS not sent")
    });

  }


  useEffect(()=>{
    getMycodeApi();
    if(islogin) navigate('/');

  },[islogin]);

  return (
      <Container component="main" maxWidth="xs">
        <Styled>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>

          </Avatar>
          <Typography component="h1" variant="h5">
          Se connecter
          </Typography>
          {
            !restPass? <Box component="form"  noValidate sx={{ mt: 1 }}>
            {
              Config.loginWithPhon?
            <PhoneInput
             required
             country={codeContry}
             variant="standard"
             fullWidth
             name="phone"
             value={email}
             margin="normal"
             autoFocus
             onChange={(value,code)=>{
             value = value.slice(code.dialCode.length);
             setemail("+"+code.dialCode+value.replace(/^0+/, ''));
             console.log(email);
             seterr("")}}
             label="Téléphone"
             regions={["europe", "africa"]}
           />:<TextField
              margin="normal"
              required
              fullWidth
              variant="standard"
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              autoFocus
              onChange={(e)=>setemail(e.target.value)&seterr("")}
            />
            }
            <TextField
              margin="normal"
              required
              fullWidth
              variant="standard"
              name="password"
              label="Mot de passe"
              type={!viewpassword?"password":"text"} 
              helperText={err}
              id="password"
              autoComplete="current-password"
              value={pass}
              onChange={(e)=>Setpass(e.target.value)&seterr("")}
              InputProps={{
                endAdornment: <InputAdornment position="end" onClick={(e)=>setviewpassword(!viewpassword)} >
                  {!viewpassword?<BsFillEyeFill/>:<BsFillEyeSlashFill/>}
                </InputAdornment>,
              }}
            />
            <LoadingButton
              onClick={handleSubmit}
              type='submit'
              variant="contained"  loading={loading}
              fullWidth
              sx={{ mt: 3, mb: 2 }}
            >
              Se connecter
            </LoadingButton>
            <Grid container>
              <Grid item xs>
              {ActiveRest?<Link onClick={(e)=>setrestPass(!restPass)&SandOtp()}>
                  {"mot de passe oublié !"}
                </Link>:""}
              </Grid>
              
              <Grid item>
                <Link onClick={(e)=>navigate('/SignUp')} variant="body2">
                  {"Vous n'avez pas de compte ? S'inscrire"}
                </Link>
              </Grid>
            </Grid>
          </Box>:<>
          <Card variant="outlined" className="sd" >
              <Grid  container spacing={2}>
                <div className="headerCF">
                <h3>Please enter the one time password to verify your account</h3> 
                <p>A code has been sent to<span>*****</span><span className="nh">{email.slice(-2)}</span></p>
                </div>
                  <Grid item className="otpVal" xs={12} sm={12}>
                  <OtpInput
                   className="valop"
                   value={otp}
                   onChange={(val)=>setotp(val)}
                   numInputs={6}
                   separator={<span>-</span>}
                   />
                  </Grid>
                  <Grid  item xs={12} sm={12}>
                    <div className="dfg">
                    <LoadingButton disabled={Disaible} variant="contained"  loading={loading} onClick={validateAccount}>
                       Validation 
                    </LoadingButton>
                       <Grid item xs={12}  className={"df"} sm={12}><OtpTimer seconds= {40} minutes={0}  resend={SandOtp} /></Grid> 
                    </div>
                </Grid> 
             </Grid>
             </Card>

          </>
          }
         
        </Box>
        <div id='Recaptcha'></div>
        </Styled>
      </Container>
  );
}
