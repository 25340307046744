
const Config = {
    url:'https://admin.taxigreen.fr/api',
    apikey:'87EE672EE7A527BF9F4AB2B8A15A1',
    content_type:'Content-type',
    img_url:'https://admin.taxigreen.fr/',
    id_partnair:"1",
    Copyright_name:"Taxigreen",
    Copyright_url:"https://taxigreen.fr/",
    googkeMapUrl:"AIzaSyCfBLheshUDoXFNSODTmni1G0teFUNzHyg",
    Region:"DZ",
    activecompany:false,
    firebase:false,
    courrancy:"€",
    pages:true,
    loginWithPhon:true,
    aviationstack:"75250a9486a0fcfaa0cb185a334e2609",
    bilalid:58049,
    conterMap:{
        lat:36.33603065349059, 
        lng: 3.0504713671846337
    },
    isActiveAcount:true,
    multiCart:false,
    activeVolPlanet:true,
    activeVoltrain:false,
    phoneContact:"+213.7.70.89.95.07",
    appUrlAnd:"https://play.google.com/store/apps/details?id=com.taxigreen.client&hl=ln&gl=US",
    appUrlIos:"https://play.google.com/store/apps/details?id=com.taxigreen.client&hl=ln&gl=US"
};
export default Config;
