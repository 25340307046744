import firebase from 'firebase/app'
import 'firebase/auth'
var firebaseConfig = {
  apiKey: "AIzaSyDtxV1PpqGKzS_QyJUyk-EUC7X8Qe_bXCw",
  authDomain: "taxi-green-14f06.firebaseapp.com",
  projectId: "taxi-green-14f06",
  storageBucket: "zencab-6064a.appspot.com",
  storageBucket: "taxi-green-14f06.appspot.com",
  messagingSenderId: "867494789531",
  appId: "1:867494789531:web:523d2df6625797214ba13e"
  //measurementId: "G-EELJJRG0NE"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
export default firebase

