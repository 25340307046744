import React, { useState, useEffect, useContext } from "react";
import {
  GoogleMap,
  Autocomplete,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import {Button,Grid,Fab, IconButton, Menu, MenuItem, DialogTitle, DialogContent, Dialog, FormGroup, FormControlLabel, Checkbox, DialogActions, Typography } from '@mui/material';
import styled from "styled-components";
import { BiMap } from "react-icons/bi";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import CmdSer from "../Service/CmdSer";
import Config from "../Config";
import { useNavigate } from "react-router-dom";
import { CartStateContext } from "../context/Main";
import moment from "moment";
import MapStyles from "../Style/MapStyles";
import { toast } from "react-toastify";
import { InputAdornment, TextField } from "@mui/material";
import Geocode from "react-geocode";
import marker1 from "../image/markers/1.png";
import marker2 from "../image/markers/2.png";
import FlightIcon from '@mui/icons-material/Flight';
import DirectionsTransitFilledIcon from '@mui/icons-material/DirectionsTransitFilled';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import HorizontalLinearStepper from "../com/HorizontalLinearStepper";
import HorizontalLinearStepperTrain from "../com/HorizontalLinearStepperTrain";
import DomainIcon from '@mui/icons-material/Domain';
import PhoneIcon from '@mui/icons-material/Phone';
import StepperVole from "../com/StepperVole";

import im1 from "../image/ap.png";
import im2 from "../image/play.png";

Geocode.setApiKey(Config.googkeMapUrl)

const StyledDiv = styled.div`

   .dfSSS{
    display: flex;
   }

  .logo img {
    max-width: 70px;
  }
  .logo {
    background-color: #fff;
    padding: 5px 25px;
    position: fixed;
    left: 2%;
    top: 15px;
    border-radius: 10px;
  }
  .gmnoprint {
    display: none;
  }
  button.gm-control-active.gm-fullscreen-control {
    display: none;
  }
  .form_sande {
    position: fixed;
    width: 540px;
    padding: 30px 20px 30px 20px;
    top: 120px;
    background-color: white;
    margin-left: 50px;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 30%) 0px 2px 6px;
  }
  .input-group .btn {
    position: relative;
    z-index: 999;
    background-color: white;
    border: none !important;
  }
  .input-group.col-lg-12 {
    margin: 10px 0;
  }
  h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    color: #443f40;
  }
  .form_sande svg {
    color: #278054 ;
    font-size: 18px;
  }
  .react-datepicker-wrapper {
    width: 100%;
    position: absolute;
  }
  .react-datepicker-wrapper input {
    width: 95%;
    padding: 4px 4px 5px 51px;
    border: none !important;
    box-shadow: rgb(0 0 0 / 30%) 0px 2px 6px;
    height: 40px;
  }
  input.react-datepicker-ignore-onclickoutside {
    border: none !important;
  }
  input.react-datepicker-ignore-onclickoutside {
    color: "#494445";
  }
  .icon_option img {
    width: 100%;
    height: 100%;
  }
  .sing_option h3 {
    font-size: 15px;
    text-align: center;
  }
  .sing_option {
    border: solid;
    padding: 9px;
    border-width: 1px;
    border-radius: 5px;
    border-color: #278054 ;
    text-align: center;
    margin: 10px 0;
  }
  .option {
    margin-top: 15px;
    padding: 0px 15px;
  }
  .ppf {
    padding-right: 3px;
  }

  p.info-text {
    background-color: #278054 ;
    border-radius: 7px;
    color: white;
    font-size: 13px;
  }
  .btn_reser button {
    width: 100%;
    margin-top: 16px;
    height: 47px;
    border: none;
    font-size: 20px;
  }
  .sing_option.active {
    background-color: #278054 ;
  }
  .sing_option.active p.info-text {
    background-color: #ffffff;
    border-radius: 7px;
    color: #443f40;
  }
  .sing_option.active h3.info-heading {
    color: #fff;
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
    margin-left: -11px;
  }
  button.fds.btn.btn-primary {
    background-color: #278054 ad;
  }
  h3.ffh {
    padding: 20px;
  }
  .fade.toast.show {
    z-index: 999999999999;
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .toast-body {
    background-color: #db0505;
    color: #fff;
  }
  @media screen and (max-width: 480px) {
    .all_option {
      position: sticky;
    }
    .option {
      margin-top: 15px;
      padding: 0px 6px;
    }
    .form_sande {
      position: initial;
      width: 100%;
      padding: 18px 4px 16px 5px;
      top: 0;
      background-color: white;
      margin-left: 0;
      border-radius: 8px;
      box-shadow: rgb(0 0 0 / 30%) 0px 2px 6px;
      height: 100vh;
      padding-top: 85px;
      overflow:scroll !important;
    }
  }

  .position_right {
    position: fixed;
    right: 1%;
    top: 2%;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 18px !important;
  }
  .position_right a {
    font-size: 16px !important;
  }
  .position_right a {
    padding: 10px 20px;
    background-color: #fff;
    border-radius: 10px;
  }
  .position_right a span {
  }
  .icon_option img {
    max-height: 150px;
    height: 100px;
    width: auto;
  }
  .MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }
  .df {
    width: 100%;
  }
`;

const containerStyle = {
  width: "100%",
  height: "100vh",
};

function Booking() {
  const [center, setcenter] = useState(Config.conterMap);
  const [zoom,Setzoom] = useState(10);
  const [fouces,setfouces] = useState(1);
  const [courrancy,setcourrancy] = useState(Config.courrancy);
  const { islogin , user} = useContext(CartStateContext);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [autocomplete1, Setautocomplete1] = useState(null);
  const [autocomplete2, Setautocomplete2] = useState(null);
  const [Nvol,SetNvol] =  useState("");
  const [Provence,SetProvence] =  useState("");

  const [NTrain,SetNTrain] = useState("");
  const [DataVol,SetDataVol] =  useState({});
  const [DataTrain,SetDataTrain] =  useState("");
  const navigate = useNavigate();
  const [add1, setadd1] = useState({
    lat:0,lng:0,address:""
  });
  const [add2, setadd2] = useState({
    lat:0,lng:0,address:""
  });
  const [startDate, setStartDate] = useState();
  //
  const [responsed, setresponse] = useState("");
  const [re, setre] = useState(true);
  // data
  const [distance, Setdistance] = useState("");
  const [duration, Setduration] = useState("");
  // type data
  const [dataTtpe, SetdataTtpe] = useState([]);
  // selected
  const [dataTypeId, SetdataTypeId] = useState(0);

  const [priceTotal,setpriceTotal] = useState(0);
  const [option,setoption] = useState([]);

  // type booking
  const [Typebooking, SetTypebooking] = useState(2);
  // menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const list addd
  const [addBase, SetaddBase] = useState([]);
  // const list fav add 
  const [addfav, Setaddfav] = useState([]);
  // controle switch 
  const [controle, Setcontrole] = useState(1);

  // function to set add 
  const setAddr = async(data) => {
    console.log(data);
    const re = await Geocode.fromLatLng(data.lat,data.lng);
      re.results.forEach(element => {
       if(element.types.find(type => type=="plus_code")){
        setcenter(element.geometry.location);
        Setzoom(15);
        switch (fouces) {
          case 1:
            setadd1({...add1,address:data.address,lat:parseFloat(data.lat),lng:parseFloat(data.lng)});
            setfouces(2);
            break;
            case 2:
              setadd2({...add2,address:data.address,lat:parseFloat(data.lat),lng:parseFloat(data.lng)});
              setfouces(3);
              break;
           default:
            setadd2({...add2,address:data.address,lat:parseFloat(data.lat),lng:parseFloat(data.lng)});
            break;
        }
       }
       setAnchorEl(null);
    });
  }


  // first input
  const onLoad1 = (autocomplete1) => {
    Setautocomplete1(autocomplete1);
  };
  const onPlaceChanged1 = (e) => {
    if (autocomplete1 !== null) {
      setadd1({...add1,address:autocomplete1.getPlace().formatted_address,lat:autocomplete1.getPlace().geometry.location.lat(),lng:autocomplete1.getPlace().geometry.location.lng()});
      setcenter(autocomplete1.getPlace().geometry.location);
      Setzoom(15);
      setfouces(2);
    }
  };
  // seconsd input
  const onLoad2 = (autocomplete2) => {
    Setautocomplete2(autocomplete2);
  };
  const onPlaceChanged2 = (e) => {
    if (autocomplete2 !== null) {
      setadd2({...add2,address:autocomplete2.getPlace().formatted_address,lat:autocomplete2.getPlace().geometry.location.lat(),lng:autocomplete2.getPlace().geometry.location.lng()});
      setcenter(autocomplete2.getPlace().geometry.location);
      Setzoom(15);
      setfouces(3);
    } 
  };

  const directionsCallback = async () => {
    if(add1.lat!=0 && add2.lat!=0){
     
        const re = await CmdSer.getMatrixApi({origins:add1.address,destinations:add2.address});
        const Data = await CmdSer.getPrice({
          km: parseFloat(re.data.rows[0].elements[0].distance.value/1000).toFixed(2),
          time: parseFloat(re.data.rows[0].elements[0].duration.value/60).toFixed(2),
          start_lat: add1.lat,
          start_lng: add1.lng,
          end_lat: add2.lat,
          end_lng: add2.lng,
          time_start : moment(selectedDate).format("HH:mm"),
          date_start : moment(selectedDate).format("YYYY-MM-DD HH:mm"),
        });
        getSymbolD(add2.lat,add2.lng);

        if(Data.data.length!=0){
          SetdataTtpe(Data.data);
          Setdistance(parseFloat(re.data.rows[0].elements[0].distance.value/1000).toFixed(2));
          Setduration(parseFloat(re.data.rows[0].elements[0].duration.value/60).toFixed(2));
        }else{
          SetdataTtpe([]);
          toast.error(`Service pas disponible dans cette région.`, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
    }
  };


  const [show, setShow] = useState(true);
  const serialize = (obj, prefix) => {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          serialize(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  }

  const SabmitRT = async () => {
    
    if (startDate != "" && dataTypeId != 0) {
      let vol = "null";
      let df = "null";
      let train  = "null";

      if(Nvol!=""){
        vol = "";
        let i = 0;
        let op = {
          /*"airport_depart": DataVol.departure.airport,
          "ville_depart": DataVol.departure.timezone,
          "date_depart": DataVol.departure.scheduled,
          "airport_arrivée": DataVol.arrival.airport,
          "ville_arrivée": DataVol.arrival.timezone,
          "date_arrivée":DataVol.arrival.scheduled,*/
          "airport_depart": "",
          "ville_depart": "",
          "date_depart": "",
          "airport_arrivée":"",
          "ville_arrivée": "",
          "date_arrivée":"",
          "numvol": Nvol,
          "provenance":Provence,
        }
         vol = Object.keys(op).map(key => key + '=' + op[key]).join('&');
      }
      if(option.length!=0){
        df = "";
        let i = 0;
        option.forEach(element => {
          var queryString = Object.keys(element).map(key => key + '=' + element[key]).join('&');
          if(i!=0) {
            df =  df +'&' + queryString;
          }else{
            df = df + queryString;
          }
          i++;
        });
  
      }



      if(NTrain!=""){
        train = "";
        let i = 0;
        let op = {
          "num_train": NTrain,
          "provenance":DataTrain
        }
        train = Object.keys(op).map(key => key + '=' + op[key]).join('&');

      }
   
      navigate(
          `/Finaletap/${add1.lat}/${add1.lng}/${add2.lat}/${add2.lng}/${add1.address}/${add2.address}/${distance}/${duration}/${dataTypeId}/${selectedDate}/${Typebooking}/${df}/${vol.replaceAll('/', '%2F')}/${train.replaceAll('/', '%2F')}`
        );
    } else {
      setShow(!show);
      toast.error("Veuillez entrer toutes les données", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    
   //console.log(`/Finaletap/${add1.lat}/${add1.lng}/${add2.lat}/${add2.lng}/${add1.address}/${add2.address}/${distance}/${duration}/${dataTypeId}/${selectedDate}/${Typebooking}`);

  };

  const markerInser =  async (data) => {
    const re = await Geocode.fromLatLng(data.latLng.lat(),data.latLng.lng());
    console.log(re);
      re.results.forEach(element => {
       if(element.types.find(type => type=="street_address" || type=="route")){
        setcenter(element.geometry.location);
        Setzoom(15);
        switch (fouces) {
          case 1:
            setadd1({...add1,address:element.formatted_address,lat:element.geometry.location.lat,lng:element.geometry.location.lng});
            setfouces(2);
          
            break;
            case 2:
              setadd2({...add2,address:element.formatted_address,lat:element.geometry.location.lat,lng:element.geometry.location.lng});
              setfouces(3)
             
              break;
           default:
            setadd2({...add2,address:element.formatted_address,lat:element.geometry.location.lat,lng:element.geometry.location.lng});
            break;
        }
       }
    });
  }


  const getSymbolD = async(lat,lng) => {
    const res_c = await CmdSer.get_devise_zone({
      lat:lat,lng:lng
    });
    if(res_c.data.id){
      if(res_c.data.symbol=="&#128;")res_c.data.symbol=Config.courrancy;
      setcourrancy(res_c.data.symbol);
    }

  }

  // get data address 
  const getAddFavBase = async () => {
    const res_add_fav = await CmdSer.fav_add(user.id);
    Setaddfav(res_add_fav.data);
    const res_add_base = await CmdSer.add_base();
    SetaddBase(res_add_base.data);
  }


  useEffect(() => {
    // login option in company
    if(Config.activecompany){
      if(!islogin){
        navigate(`/Login`);
      }
    }
    getAddFavBase();
    setShow(false);
    navigator.geolocation.getCurrentPosition(function (position) {
      setcenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);


  useEffect(()=>{
    directionsCallback();
  },[add1]);

  useEffect(()=>{
    directionsCallback();
  },[add2]);

  useEffect(()=>{
    directionsCallback();
  },[selectedDate]);
  

  /*

    
*/



  useEffect(()=>{
    if(dataTtpe.length!=0 && dataTypeId!=0){
      let price = parseFloat(dataTtpe.filter(dataTtpe =>dataTtpe.id.includes(dataTypeId))[0].pric_total);
      option.forEach(element => {
        price = price+parseFloat(element.price);
       
      });
      setpriceTotal(price);

    }

  },[option])



  useEffect(()=>{
    if(dataTtpe.length!=0 && dataTypeId!=0){
    setpriceTotal(parseFloat(dataTtpe.filter(dataTtpe =>dataTtpe.id.includes(dataTypeId))[0].pric_total));
  }
  },[dataTypeId])
// dailog options
  const [openDialog, setopenDialog] = React.useState(false);
  const handleClickOpen = async (data) => {
    if(data.json_option.length){
      setoption([]);

      setopenDialog(true);
    }
    
  };

  const handleCloseDialog = () => {
    setopenDialog(false);
  };
// dailog vol
 const [openDialogVol, setopenDialogVol] = React.useState(false);
 const handleCloseDialogVol = () => {
  setopenDialogVol(false);
};
const handleClickOpenVol = async () => {
  if(fouces==1 & Config.activeVolPlanet) setopenDialogVol(true);

};
// dailog train
const [openDialogTrain, setopenDialogTrain] = React.useState(false);
const handleCloseDialogTrain = () => {
 setopenDialogTrain(false);
};
const handleClickOpenTrain = async () => {
 if(fouces==1 & Config.activeVoltrain) setopenDialogTrain(true);

};

const [openDialogappInfo, setopenDialogappInfo] = React.useState(false);

const viewDailoge =  async () => {
if(new Date(new Date().setHours(new Date().getHours() + 2)) > selectedDate){
  setopenDialogappInfo(true);
}else{
  toast.error("Veuillez sélectionner Gammes de Véhicules", {
    position: toast.POSITION.BOTTOM_LEFT,
  });
}
}


  return (
    <StyledDiv>
      <GoogleMap
        mapContainerStyle={

          containerStyle}
        center={center}
        options={{
           draggableCursor:fouces!=3?`url(${fouces==1?marker1:marker2}) 20 40,auto`:"default",
        
          styles: MapStyles,
        }}

        zoom={zoom}
        
        onClick={
          (palce)=>(fouces==1||fouces==2)?markerInser(palce):null
        }
      >
        {/* form style */}
        <div className="all_option">
          <div className="form_sande">
            <h3 className="ffh">VOTRE TRAJET</h3>
            <Grid container spacing={2}>
              <Grid Grid item xs={12}>
                <Autocomplete
                  className="df"
                  options={{
                    componentRestrictions:{
                      country : Config.Region
                    }
                   }}
                  onLoad={(e) => onLoad1(e)}
                  onPlaceChanged={(e) => onPlaceChanged1(e)}
                >
                  <TextField
                   size="small"
                    id="standard-basic"
                    label="Adresse de départ"
                    variant="standard"
                    placeholder=""
                    onClick={(e)=>setfouces(1)}
                    focused={fouces==1?true:false}
                    value={add1.address}
                    onChange={(e)=>setadd1({...add1,address:e.target.value})}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <BiMap />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Autocomplete>
                <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                className="menu_option"
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                 }}
                 >
                  {(() => {
                   switch(controle) {
                    case 1: return addfav.length?addfav.map((data,i)=><MenuItem key={i} onClick={(e)=>handleClose&&setAddr(data)}>{data.address}</MenuItem>):<MenuItem>None</MenuItem>
                    case 2: return  addBase.length?addBase.filter(addBase =>addBase.type.includes('2')).map((data,i)=><MenuItem key={i} onClick={(e)=>handleClose&&setAddr(data)&&handleClickOpenVol()}>{data.address}</MenuItem>):<MenuItem>None</MenuItem>
                    case 3: return addBase.length?addBase.filter(addBase =>addBase.type.includes('1')).map((data,i)=><MenuItem key={i} onClick={(e)=>handleClose&&setAddr(data)&&handleClickOpenTrain()}>{data.address}</MenuItem>):<MenuItem>None</MenuItem>
                  }
                 })()}
                  {
                    
                    
                   
                  }

                </Menu>
                <div className="list_add_fav">
                {islogin?<IconButton color="primary" onClick={(e)=>Setcontrole(1)&handleClick(e)} aria-label="upload picture" component="span"><FavoriteBorderIcon /></IconButton>:<></>}
                <IconButton color="primary" onClick={(e)=>Setcontrole(2)&handleClick(e)}aria-label="upload picture" component="span"><FlightIcon /></IconButton>
                <IconButton color="primary" onClick={(e)=>Setcontrole(3)&handleClick(e)} aria-label="upload picture" component="span"><DomainIcon /></IconButton>

                </div>


              </Grid>

              <Grid Grid item xs={12}>
                <Autocomplete
                  className="df"
                 options={{
                  componentRestrictions:{
                    country : Config.Region
                  }
                 }}
                  onLoad={(e) => onLoad2(e)}
                  onPlaceChanged={(e) => onPlaceChanged2(e)}
                >
                  <TextField
                   size="small"
                    id="standard-basic"
                    label="Adresse de destination"
                    variant="standard"
                    placeholder=""
                    focused={fouces==2?true:false}
                    value={add2.address}
                    onClick={(e)=>setfouces(2)}
                    onChange={(e)=>setadd2({...add2,address:e.target.value})}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <BiMap />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Autocomplete>
                <Menu
                id="demo-positioned-menu2"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                className="menu_option"
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                 }}
                 >
                  {(() => {
                   switch(controle) {
                    case 1: return addfav.length?addfav.map((data,i)=><MenuItem key={i} onClick={(e)=>handleClose&&setAddr(data)}>{data.address}</MenuItem>):<MenuItem>None</MenuItem>
                    case 2: return  addBase.length?addBase.filter(addBase =>addBase.type.includes('2')).map((data,i)=><MenuItem key={i} onClick={(e)=>handleClose&&setAddr(data)&&handleClickOpenVol()}>{data.address}</MenuItem>):<MenuItem>None</MenuItem>
                    case 3: return addBase.length?addBase.filter(addBase =>addBase.type.includes('1')).map((data,i)=><MenuItem key={i} onClick={(e)=>handleClose&&setAddr(data)&&handleClickOpenTrain()}>{data.address}</MenuItem>):<MenuItem>None</MenuItem>
                  
                  }
                 })()}
                  {
                    
                    
                   
                  }

                </Menu>
                <div className="list_add_fav">
                 {islogin?<IconButton color="primary" onClick={(e)=>Setcontrole(1)&handleClick(e)} aria-label="upload picture" component="span"><FavoriteBorderIcon /></IconButton>:<></>}
                <IconButton color="primary" onClick={(e)=>Setcontrole(2)&handleClick(e)}aria-label="upload picture" component="span"><FlightIcon /></IconButton>
                <IconButton color="primary" onClick={(e)=>Setcontrole(3)&handleClick(e)} aria-label="upload picture" component="span"><DomainIcon /></IconButton>

                </div>
              </Grid>
              <Grid Grid item xs={6}>
                <DatePicker
                  renderInput={(props) => <TextField  size="small" variant="standard" {...props} />}
                  label="Date"
                  disabled = {/*Nvol!=""*/ false}
                  format="dd/MM/YYYY"
                  value={selectedDate}
                  onChange={handleDateChange}
                  inputFormat="dd-MM-yyy"
                  />
         
                
              </Grid>
              <Grid Grid item xs={6}>
              <TimePicker
                ampm={false}
                label="Heure"
                disabled = {/*Nvol!=""*/ false}
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(params) => <TextField   size="small" variant="standard" {...params} />}
                />
              </Grid>
              <Grid Grid item xs={12}>
                {dataTtpe.length ? (
                  <div className="df">
                    <Grid container spacing={dataTtpe.length!=1?2:0} >
                      {dataTtpe.map((item, i) => (
                        <>
                          <Grid
                            key={i}
                            className="ppf"
                            xs={12}
                            lg={3}
                            md={3}
                          >
                            <div
                              onClick={(e) => SetdataTypeId(item.id)&handleClickOpen(item)}
                              className={
                                dataTypeId == item.id
                                  ? "sing_option active"
                                  : "sing_option "
                              }
                            >
                              <div className="icon_option">
                                <img src={Config.img_url + item.icon} />
                              </div>
                              <h3 className="info-heading">{item.name}</h3>
                              <p className="info-text">
                                {item.pric_total} {courrancy}
                              </p>
                            </div>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </div>
                ) : (
                  <></>
                )}

                <div className="btn_reser">
                  {dataTtpe.length && new Date(new Date().setHours(new Date().getHours() + 2)) < selectedDate ? (
                    <Button onClick={(e) => SabmitRT()} variant="contained" >
                    Commander { "" + priceTotal +  courrancy + ""}
                    </Button>
                  ) : (
                    <Button  onClick={(e) => viewDailoge()} className="fds" variant="contained" color="secondary">
                      Commander
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        {

        }
        {add1.address!=""&&add1.lat!=0?<Marker 
         icon={marker1}
        title={add1.address}
        position={{ lat: add1.lat, lng: add1.lng }}
         />:<></>}
   {add2.address!=""&&add2.lat!=0?<Marker 
    icon={marker2}
   title={add2.address}
   position={{ lat: add2.lat, lng: add2.lng }}
    />:<></>}

{(add2.address!=""&& add2.lat!=0 && add1.address!="" && add1.lat!=0)?
<Polyline  path={[{ lat: add1.lat, lng: add1.lng },{ lat: add2.lat, lng: add2.lng }]}/>:
<></>}

        {/*add1 !== "" && add2 !== "" && responsed == "" && (
          <DirectionsService
            // required
            options={{
              destination: {
                lat: autocomplete2.getPlace().geometry.location.lat(),
                lng: autocomplete2.getPlace().geometry.location.lng(),
              }, // .getPlace().geometry.location.lat()
              origin: {
                lat: autocomplete1.getPlace().geometry.location.lat(),
                lng: autocomplete1.getPlace().geometry.location.lng(),
              },
              travelMode: "DRIVING",
            }}
            // required
            callback={directionsCallback}
          />
        )}

        {responsed != "" && (
          <DirectionsRenderer
            // required
            options={{
              directions: responsed,
              markerOptions: {
                icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Map_marker.svg/30px-Map_marker.svg.png"
              },

            }}
            // optional
          />
          
          )*/}
      </GoogleMap>




      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
               aria-labelledby="draggable-dialog-title"
      >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          List des options
        </DialogTitle>
        <DialogContent>
          {

                dataTtpe.length?dataTtpe.filter(dataTtpe =>dataTtpe.id.includes(dataTypeId)).map((x,i)=>x.json_option.map((data,j)=><FormGroup key={j}>
                <FormControlLabel control={<Checkbox onClick={(e)=>{

                  if(e.target.checked){
                    console.log("add");
                    console.log(option)
                    let dataold = option;
                    if(dataold.length==0){
                     setoption([...option,data]);
                  
                    }else{
                      let dataold = option;
                      if(dataold.filter(item=> item.name == data.name).length==0){
                        setoption([...option,data]);

                      }
                    }
                  }else{
                    console.log("remove");
                    let dataold = option;
                    let newdata = [];
                    dataold.forEach(element => {
                      if(element.name != data.name){
                        newdata.push(element);
                      }
                    });
                    setoption(newdata);
             
                  }
      
                
                }

              }  />} label={data.name + " " + "("+data.price + " "+courrancy+")"} />
                </FormGroup>)):<></>
           
          }
          <div className="price_aff">
             <div className="succ">Prix normal : <span>{dataTtpe.length&&dataTypeId!=0?dataTtpe.filter(dataTtpe =>dataTtpe.id.includes(dataTypeId))[0].pric_total + " "+courrancy:<></>}</span></div>
             <div className="final">Prix total : <span>{priceTotal + " "+courrancy}</span></div>
          </div>
         
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={(e)=>handleCloseDialog()&setoption([])}>Pas d'option </Button>
          <Button color="success" onClick={handleCloseDialog} autoFocus>
            Valider
          </Button>
        </DialogActions>
      </Dialog>




       <Dialog
        open={openDialogVol}
        onClose={handleCloseDialogVol}
        aria-labelledby="draggable-dialog-title"
        id="VolOption"
      >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Suivi de votre vol
        </DialogTitle>
        <DialogContent>
        {/*<HorizontalLinearStepper Nvol={Nvol}  Provence={Provence} SetProvence={SetProvence} SetNvol = {SetNvol} DataVol={DataVol} SetDataVol={SetDataVol}  close={handleCloseDialogVol}  Date={selectedDate} setDate={handleDateChange}  />*/}
        <StepperVole  Nvol={Nvol}  Provence={Provence} SetProvence={SetProvence} SetNvol = {SetNvol} close={handleCloseDialogVol}  DataVol={DataVol} SetDataVol={SetDataVol}/>
        
        </DialogContent>
      </Dialog>





      {/* Dialog App */}
      <Dialog
        open={openDialogappInfo}
        onClose={(e)=>setopenDialogappInfo(!openDialogappInfo)}
        aria-labelledby="draggable-dialog-title"
        id="ghhhhh"

      >
      <DialogTitle  style={{ cursor: 'move' }} id="draggable-dialog-title">
              <Typography variant="h6">Attention</Typography> 
        </DialogTitle>
        <DialogContent>
        Pour toute demande immédiate ou dans moins de 2H merci de bien vouloir utiliser notre application mobile ou bien nous contacter au 
        <br/><br/>
        <Grid container textAlign={"center"} spacing={2}>

          <Grid Grid item xs={12}>
       
           <Button variant="text" color="success" href={"tel:"+Config.phoneContact} startIcon={<PhoneIcon />}>{Config.phoneContact}</Button>
         </Grid>
         <Grid Grid item  xs={6}>
          <a  href={Config.appUrlIos}><img src={im1} /></a>
         </Grid>
         <Grid Grid item xs={6}>
          <a  href={Config.appUrlAnd}><img src={im2} /></a>
         </Grid>
       </Grid>
        </DialogContent>
      </Dialog>





      <Dialog
        open={openDialogTrain}
        onClose={handleCloseDialogTrain}
        aria-labelledby="draggable-dialog-title"
        id="VolOption"
      >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Suivi de votre vol
        </DialogTitle>
        <DialogContent>
        <HorizontalLinearStepperTrain NTrain={NTrain} SetNTrain = {SetNTrain} DataTrain={DataTrain} SetDataTrain = {SetDataTrain} close={handleCloseDialogTrain} />
        </DialogContent>
      </Dialog>


    
    </StyledDiv>
  );
}

export default Booking;
